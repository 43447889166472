import { render, staticRenderFns } from "./Oliveira-Do-Bairro.vue?vue&type=template&id=5fbd5bb6&scoped=true&"
import script from "./Oliveira-Do-Bairro.vue?vue&type=script&lang=js&"
export * from "./Oliveira-Do-Bairro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbd5bb6",
  null
  
)

export default component.exports